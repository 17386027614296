'use strict';

$.fn.datepicker.languages['de-DE'] = {
    format: 'dd.mm.yyyy',
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    daysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    weekStart: 1,
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

var priceFinder = {
    customerElement: function customerElement() {
        return this.container.find('.m-price-calculator__consumption-buttons');
    },
    postalElement: function postalElement() {
        return this.container.find('input[name="tx_leu_pi2[postal]"]');
    },
    consumptionElement: function consumptionElement() {
        return this.container.find('input[name="tx_leu_pi2[consumption]"]');
    },
    init: function init() {
        var that = this;
        this.customerElement().find('button').click(function () {
            that.byCustomer($(this));
        });
    },
    byCustomer: function byCustomer(button) {
        var identifier = parseInt(button.attr('data-consumption'));
        var value = this.customer[identifier];
        this.consumptionElement().val(this.formatNumber(value));

        this.validateHiddenField(this.consumptionElement(), value);

        this.setActive(identifier);
    },
    setActive: function setActive(consumption) {
        this.customerElement().find('button').removeClass('selected');
        this.customerElement().find('button[data-consumption="' + consumption + '"]').addClass('selected');
    },
    byConsumption: function byConsumption() {
        var consumption = parseInt(this.consumptionElement().cleanVal());
        this.validateHiddenField(this.consumptionElement(), consumption);

        var customerObjectLength = Object.keys(this.customer).length;
        if (consumption >= this.customer[customerObjectLength]) {
            this.setActive(customerObjectLength);
        }
        if (consumption <= this.customer[1]) {
            this.setActive(1);
        }

        for (var index in this.customer) {
            var i = parseInt(index);
            if (consumption >= this.customer[i] && consumption <= this.customer[i + 1]) {
                this.setActive(i);
            }
        }
    },
    formatNumber: function formatNumber(number) {
        return number.toLocaleString(undefined, {
            minimumFractionDigits: 0
        });
    },
    validateHiddenField: function validateHiddenField(field, value) {
        var fieldName = field.attr('name');
        var hiddenField = field.parents('form').find('[name="' + fieldName + '.hidden"]');
        hiddenField.val(value);
    }
};

var tarrifs = {
    init: function init() {
        $('[data-tarrif]').each(function () {
            var tariffBox = $(this);
            if (tariffBox.length > 0) {
                var tariffHash = tariffBox.attr('data-tarrif');

                $.ajax({
                    method: "GET",
                    url: "https://portal.leu-energie.de/web/1xnMUvCtt6NwKD5K/" + tariffHash + "/tariff"
                }).done(function (result) {
                    var tariff = JSON.parse(result);
                    var tariffKeys = Object.keys(tariff);
                    tariffKeys.forEach(function (i) {
                        var dataElement = tariffBox.find('[data-tarrif-data="' + i + '"]');
                        if (dataElement.length > 0) {
                            dataElement.text(tariff[i]);
                        }
                    });
                });
            }
        });
    }
};

var contactForm = {
    init: function init() {
        $('#contactForm').submit(function (e) {
            e.preventDefault();
            contactForm.removeAlert();

            var captcha = grecaptcha.getResponse();
            if (captcha.length == 0) {
                contactForm.alert('alert-danger', 'Bitte aktivieren Sie "Ich bin kein Roboter".');
            }

            var data = {
                firstname: $('#firstname').val(),
                lastname: $('#lastname').val(),
                email: $('#email').val(),
                phone: $('#phone').val(),
                zip: $('#zip').val(),
                consumption: $('#consumption').val(),
                message: $('#message').val(),
                captcha: captcha
            };

            contactForm.showLoader();

            $.ajax({
                method: "POST",
                url: "mail.php",
                data: {
                    form: data
                }
            }).done(function (result) {
                var code = parseInt(result);
                if (code == 1) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Bitte geben Sie eine Email-Adresse an.');
                }
                if (code == 2) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Bitte geben Sie eine Nachricht an.');
                }
                if (code == 3) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Bitte aktivieren Sie "Ich bin kein Roboter".');
                }
                if (code == 4) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Bitte geben Sie eine gültige Email-Adresse an.');
                }
                if (code == 5) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Sie konnten leider nicht verifiziert werden, bitte Laden Sie das Formular neu.');
                }
                if (code == 6) {
                    contactForm.hideLoader();
                    contactForm.alert('alert-danger', 'Ihre Nachricht konnte leider nicht versendet werden, bitte versuchen Sie es erneut.');
                }
                if (code == 7) {
                    contactForm.hideLoader();
                    $('#modal--contact').find('.modal-footer').find('[type="submit"]').remove();
                    $('#modal--contact').find('.o-modal--content').html('<div class="alert-container"></div>');
                    contactForm.alert('alert-success', 'Ihre Nachricht wurde erfolgreich versendet.');
                }
            });
            return false;
        });
    },
    showLoader: function showLoader() {
        $('.o-modal--content').hide();
        $('.o-modal--loader').show();
    },
    hideLoader: function hideLoader() {
        $('.o-modal--loader').hide();
        $('.o-modal--content').show();
    },
    alert: function alert(type, text) {
        $('.alert-container').html('<div class="alert ' + type + '" role="alert">' + text + '</div>');
    },
    removeAlert: function removeAlert() {
        $('.alert-container').html('');
    }
};

$(document).ready(function () {
    tarrifs.init();
    contactForm.init();

    var priceFinderHeatingOil = Object.create(priceFinder);
    priceFinderHeatingOil.container = $('.m-price-calculator--heating-oil');
    priceFinderHeatingOil.customer = {
        1: 1000,
        2: 3000,
        3: 5000,
        4: 10000
    };
    priceFinderHeatingOil.init();
    priceFinderHeatingOil.postalElement().mask("00000");
    priceFinderHeatingOil.consumptionElement().mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderHeatingOil.byConsumption();
        }
    });

    var priceFinderPellets = Object.create(priceFinder);
    priceFinderPellets.container = $('.m-price-calculator--pellets');
    priceFinderPellets.customer = {
        1: 1500,
        2: 9000,
        3: 16500,
        4: 23000
    };
    priceFinderPellets.init();
    priceFinderPellets.postalElement().mask("00000");
    priceFinderPellets.consumptionElement().mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderPellets.byConsumption();
        }
    });

    var priceFinderElectricity = Object.create(priceFinder);
    priceFinderElectricity.container = $('.m-price-calculator--electricity');
    priceFinderElectricity.customer = {
        1: 1500,
        2: 2500,
        3: 3500,
        4: 4500
    };
    priceFinderElectricity.init();
    priceFinderElectricity.postalElement().mask("00000");
    priceFinderElectricity.consumptionElement().mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderElectricity.byConsumption();
        }
    });

    var priceFinderNaturalgas = Object.create(priceFinder);
    priceFinderNaturalgas.container = $('.m-price-calculator--naturalgas');
    priceFinderNaturalgas.customer = {
        1: 6000,
        2: 16000,
        3: 26000,
        4: 60000
    };
    priceFinderNaturalgas.init();
    priceFinderNaturalgas.postalElement().mask("00000");
    priceFinderNaturalgas.consumptionElement().mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderNaturalgas.byConsumption();
        }
    });

    var priceFinderBoth = $('.m-price-calculator--both');

    var priceFinderBothPostal = priceFinderBoth.find('input[name="postal"]');
    priceFinderBothPostal.mask("00000");

    var priceFinderBothElectricityConsumption = priceFinderBoth.find('input[name="consumptionElectricity"]');
    var priceFinderBothElectricityCustomerButtons = $('.m-price-calculator--both-electricity .m-price-calculator__consumption-buttons');
    priceFinderBothElectricityConsumption.mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderByConsumption(priceFinderBothElectricityConsumption, priceFinderElectricity.customer, priceFinderBothElectricityCustomerButtons);
        }
    });
    priceFinderBothElectricityCustomerButtons.find('button').click(function () {
        priceFinderByCustomer($(this), priceFinderElectricity.customer, priceFinderBothElectricityConsumption, priceFinderBothElectricityCustomerButtons);
    });

    var priceFinderBothNaturalgasConsumption = priceFinderBoth.find('input[name="consumptionGas"]');
    var priceFinderBothNaturalgasCustomerButtons = $('.m-price-calculator--both-naturalgas .m-price-calculator__consumption-buttons');
    priceFinderBothNaturalgasConsumption.mask("000.000", {
        reverse: true,
        onChange: function onChange() {
            priceFinderByConsumption(priceFinderBothNaturalgasConsumption, priceFinderNaturalgas.customer, priceFinderBothNaturalgasCustomerButtons);
        }
    });
    priceFinderBothNaturalgasCustomerButtons.find('button').click(function () {
        priceFinderByCustomer($(this), priceFinderNaturalgas.customer, priceFinderBothNaturalgasConsumption, priceFinderBothNaturalgasCustomerButtons);
    });

    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
    var back_to_top_button = ['<div class="a-scrolltop__wrapper"><a href="#section-top" class="a-scrolltop"><i class="a-icon-chevron-right"></i></a></div>'].join("");
    $("body").append(back_to_top_button);

    // Der Button wird ausgeblendet
    $(".a-scrolltop").hide();

    // Funktion für das Scroll-Verhalten
    if ($('.t-e4y__main').length > 0) {
        $(function () {
            $('.t-e4y__main').scroll(function () {
                if ($(this).scrollTop() > 100) {
                    // Wenn 100 Pixel gescrolled wurde
                    $('.a-scrolltop').slideDown("slow");
                } else {
                    $('.a-scrolltop').slideUp("fast");
                }
            });

            $('.a-scrolltop').click(function () {
                // Klick auf den Button
                $('.t-e4y__main').animate({
                    scrollTop: 0
                }, 800);
                return false;
            });
        });
    }

    checkRadioGroupStates();
    $('.a-radio-group label').click(function () {
        $(this).parent().find('label').removeClass('checked');
        $(this).parent().find('input[type="radio"]').attr('checked', false);
        $(this).addClass('checked');
        $(this).find('input[type="radio"]').attr('checked', true);

        if ($(this).parent().attr('data-collapse') !== undefined && $(this).parent().attr('data-collapse').length > 0) {
            var target = $(this).parent().attr('data-collapse');
            if (parseInt($(this).find('input[type="radio"]').val()) === 1) {
                $(target).collapse('show');
            } else {
                $(target).collapse('hide');
            }
        }

        checkRadioGroupStates();
    });

    $('.formcache').each(function () {
        var id = $(this).attr('id');
        $('#' + id).formcache({
            key: id
        });
    });

    var pluginFormArray = ['tx_leu_pi2', 'tx_leu_pi8'];

    pluginFormArray.forEach(function (formName) {

        $('[name="' + formName + '[phone]"]').mask('000000000000000000000');

        var ibanField = $('[name="' + formName + '[iban]"]');
        ibanField.mask('SS00 0000 0000 0000 0000 00', {
            placeholder: '____ ____ ____ ____ ____ __',
            onComplete: function onComplete(cep) {
                setIbanData(cep, formName);
            }
        });
        if (ibanField.length > 0) {
            setIbanData(ibanField.val(), formName);
        }
        $('[name="' + formName + '[postal]"].mask').mask("00000");
        $('[name="' + formName + '[consumption]"].mask').mask("000.000", {
            reverse: true
        });
    });

    function setIbanData(iban, formName) {
        if (iban.length > 0) {
            $.ajax({
                method: "GET",
                url: "https://portal.leu-energie.de/portal/financial/api/" + iban
            }).done(function (result) {
                if (result.length > 0) {
                    $('[name="' + formName + '[iban]"]').parent().find('small').text('');
                    var data = JSON.parse(result);
                    if (data.institute) {
                        $('[name="' + formName + '[bank]"]').val(data.institute);
                        $('[name="' + formName + '[bic]"]').val(data.bic);
                        $('[name="' + formName + '[iban]"]').parent().find('small').text(data.bic + ' - ' + data.institute);
                    }
                }
            });
        }
    }

    $('[name="tx_leu_pi2[previous_provider]"]').autocomplete({
        serviceUrl: 'https://portal.leu-energie.de/portal/provider/autocomplete',
        params: {
            type: $('[name="tx_leu_pi2[previous_provider_type]"]').val()
        },
        minChars: 3,
        onSelect: function onSelect(suggestion) {
            $(this).parent().find('[name="tx_leu_pi2[previous_provider]"]').val(suggestion.value);
            $(this).parent().find('[name="tx_leu_pi2[previous_provider_id]"]').val(suggestion.data);
        }
    });

    $("#tx_leu_pi2_step4").submit(function (e) {
        $('.formcache').formcache('removeCaches');
    });
    $("#tx_leu_pi8_step3").submit(function (e) {
        $('.formcache').formcache('removeCaches');
    });

    if ($('[id^=tx_leu_pi8_step]').length > 0) {
        var deliveryStartDate = new Date();
        var deliveryEndDate = new Date();

        deliveryStartDate.setDate(deliveryStartDate.getDate() + 1);
        deliveryEndDate.setDate(deliveryEndDate.getDate() + parseInt($('[data-datepicker-max-days]').attr('data-datepicker-max-days')));

        $('[name="tx_leu_pi8[delivery_date]"]').datepicker({
            format: 'dd.mm.yyyy',
            language: 'de-DE',
            autohide: true,
            weekStart: 1,
            filter: function filter(date) {
                if (date.getDay() === 0) {
                    return false;
                }
                if (date.getDay() === 6) {
                    return false;
                }
            }
        }).datepicker('setStartDate', deliveryStartDate).datepicker('setEndDate', deliveryEndDate);

        var deliveryDays = [];
        $('[data-delivery-days]').each(function () {
            deliveryDays.push(parseInt($(this).attr('data-delivery-days')));
        });

        $('body').on('pick.datepicker', function (e) {

            $('[data-delivery-days]').addClass('d-none');

            var selectedDate = e.date;
            var now = new Date();
            now.setHours(0, 0, 0, 0);

            var diffTime = Math.abs(selectedDate - now);
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            for (var i = 0; i < deliveryDays.length; i++) {
                if (diffDays <= deliveryDays[i]) {
                    $('[data-delivery-days="' + deliveryDays[i] + '"]').removeClass('d-none');
                    break;
                }
            }
        });
    }
});

function checkRadioGroupStates() {
    var datePickerOptions = {
        format: 'dd.mm.yyyy',
        language: 'de-DE',
        autohide: true,
        weekStart: 1
    };

    var typeValue = parseInt($('[name="tx_leu_pi2[type]"]:checked').val());

    var terminationValue = 0;
    if ($('#terminationGroup').is(':visible')) {
        terminationValue = parseInt($('[name="tx_leu_pi2[termination]"]:checked').val());
    }

    if (typeValue === 1) {
        $('#previousProviderGroup').removeClass('hidden');
        $('#terminationGroup').removeClass('hidden');
        $('#endOfContractGroup').removeClass('hidden');
        $('#endOfContractHelptext').addClass('hidden');

        $('[name="tx_leu_pi2[point_of_delivery]"]').closest('.a-radio-group').removeClass('d-none');
        $('[name="tx_leu_pi2[point_of_delivery]"]').closest('.a-radio-group').find('label').removeClass('checked');
        $('[name="tx_leu_pi2[point_of_delivery]"]:checked').parent().addClass('checked');
    } else if (typeValue === 2) {
        $('#previousProviderGroup').addClass('hidden');
        $('#terminationGroup').addClass('hidden');
        $('#endOfContractGroup').addClass('hidden');
        $('#endOfContractHelptext').removeClass('hidden');

        $('[name="tx_leu_pi2[point_of_delivery]"][value="2"]').attr('checked', true);
        $('[name="tx_leu_pi2[point_of_delivery]"][value="1"]').attr('checked', false);
        $('[name="tx_leu_pi2[point_of_delivery]"]').closest('.a-radio-group').addClass('d-none');
    } else if (typeValue === 3) {
        $('#previousProviderGroup').addClass('hidden');
        $('#terminationGroup').addClass('hidden');
        $('#endOfContractGroup').addClass('hidden');
        $('#endOfContractHelptext').removeClass('hidden');

        $('[name="tx_leu_pi2[point_of_delivery]"][value="2"]').attr('checked', true);
        $('[name="tx_leu_pi2[point_of_delivery]"][value="1"]').attr('checked', false);
        $('[name="tx_leu_pi2[point_of_delivery]"]').closest('.a-radio-group').addClass('d-none');
    }

    var pointOfDeliveryValue = parseInt($('[name="tx_leu_pi2[point_of_delivery]"]:checked').val());

    //
    var deliveryBeginningStartDate = new Date();
    var deliveryBeginningEndDate = new Date();
    if (typeof beginDeliveryDeadlineInMonths !== 'undefined' && beginDeliveryDeadlineInMonths > 0) {
        deliveryBeginningEndDate.setMonth(deliveryBeginningEndDate.getMonth() + beginDeliveryDeadlineInMonths);
    } else {
        deliveryBeginningEndDate.setDate(deliveryBeginningEndDate.getDate() + 337);
    }

    if (typeValue === 1) {
        deliveryBeginningStartDate.setDate(deliveryBeginningStartDate.getDate() + 28);
    } else {
        deliveryBeginningStartDate.setDate(deliveryBeginningStartDate.getDate() - 28);
    }

    var newDatepickerOptions = datePickerOptions;
    newDatepickerOptions.startDate = deliveryBeginningStartDate;
    newDatepickerOptions.endDate = deliveryBeginningEndDate;
    $('[name="tx_leu_pi2[delivery_beginning]"]').datepicker(newDatepickerOptions).datepicker('setStartDate', deliveryBeginningStartDate).datepicker('setEndDate', deliveryBeginningEndDate);

    $('[name="tx_leu_pi2[delivery_beginning]"]').data('minDate', deliveryBeginningStartDate).data('maxDate', deliveryBeginningEndDate);

    $('[name="tx_leu_pi2[delivery_beginning]"]').on('change', function (event) {
        var $that = $(this),
            currentDate = $that.datepicker('getDate'),
            minDate = $that.data('minDate'),
            maxDate = $that.data('maxDate');

        if (minDate && maxDate && currentDate) {
            if (currentDate < minDate || currentDate > maxDate) {
                this.setCustomValidity('Ihr Wunschtermin liegt zu weit in der Zukunft.');
                $that.parent().find('.feedback-text').text('Ihr Wunschtermin liegt zu weit in der Zukunft.');
            } else {
                this.setCustomValidity('');
                $that.parent().find('.feedback-text').text('Bitte geben Sie einen Wunschtermin ein.');
            }
        } else if (!currentDate) {
            $that.parent().find('.feedback-text').text('Bitte geben Sie einen Wunschtermin ein.');
        }
    });

    var endOfContractStartDate = new Date();
    endOfContractStartDate.setDate(endOfContractStartDate.getDate() + 27);

    var endOfContractDatepickerOptions = datePickerOptions;
    endOfContractDatepickerOptions.startDate = deliveryBeginningStartDate;
    $('[name="tx_leu_pi2[end_of_contract]"]').datepicker(endOfContractDatepickerOptions).datepicker('setStartDate', deliveryBeginningStartDate);

    if (pointOfDeliveryValue === 1) {
        $('#deliveryBeginningGroup').addClass('hidden');
        $('[name="tx_leu_pi2[delivery_beginning]"]').val('');
    } else if (pointOfDeliveryValue === 2) {
        $('#deliveryBeginningGroup').removeClass('hidden');
    }

    if (terminationValue === 1) {
        $('#endOfContractGroup').removeClass('hidden');
        $('#endOfContractHelptext').addClass('hidden');
    } else {
        $('#endOfContractGroup').addClass('hidden');
        $('#endOfContractHelptext').removeClass('hidden');
        $('[name="tx_leu_pi2[end_of_contract]"]').val('');
    }

    setTimeout(function () {

        if ($('[id^=tx_leu_pi2_step]').length > 0 || $('[id^=tx_leu_pi8_step]').length > 0) {
            $('[required="required"]').each(function () {
                if ($(this).is(':hidden')) {
                    $(this).removeAttr('required');
                    $(this).attr('data-required', true);
                }
            });
            $('[data-required="true"]').each(function () {
                if (!$(this).is(':hidden')) {
                    $(this).attr('required', 'required');
                    $(this).removeAttr('data-required');
                }
            });
        }
    }, 500);
}

function priceFinderByCustomer(button, consumptions, consumptionElement, customerButtons) {
    var identifier = parseInt(button.attr('data-consumption'));
    var value = consumptions[identifier];
    consumptionElement.val(priceFinderFormatNumber(value));

    priceFinderValidateHiddenField(consumptionElement, value);
    priceFinderSetActive(identifier, customerButtons);
}

function priceFinderFormatNumber(number) {
    return number.toLocaleString(undefined, {
        minimumFractionDigits: 0
    });
}

function priceFinderByConsumption(consumptionElement, consumptions, buttonElement) {
    var consumption = parseInt(consumptionElement.cleanVal());
    priceFinderValidateHiddenField(consumptionElement, consumption);

    var customerObjectLength = Object.keys(consumptions).length;
    if (consumption >= consumptions[customerObjectLength]) {
        priceFinderSetActive(customerObjectLength, buttonElement);
    }
    if (consumption <= consumptions[1]) {
        priceFinderSetActive(1, buttonElement);
    }

    for (var index in consumptions) {
        var i = parseInt(index);
        if (consumption >= consumptions[i] && consumption <= consumptions[i + 1]) {
            priceFinderSetActive(i, buttonElement);
        }
    }
}

function priceFinderValidateHiddenField(field, value) {
    var fieldName = field.attr('name');
    var hiddenField = field.parents('form').find('[name="' + fieldName + '.hidden"]');
    hiddenField.val(value);
}

function priceFinderSetActive(consumption, buttonElement) {
    buttonElement.find('button').removeClass('selected');
    buttonElement.find('button[data-consumption="' + consumption + '"]').addClass('selected');
}

$(document).ready(function () {
    // Initialize owl carousel for hero
    $.fn.andSelf = function () {
        return this.addBack.apply(this, arguments);
    };

    var totalItems = $('.o-hero__slider-item').length;

    if (totalItems === 1) {
        var isLooped = false;
        var isDraggable = false;
        var isDots = false;
    } else {
        var isLooped = true;
        var isDraggable = true;
        var isDots = true;
    }

    $("#hero-slider").owlCarousel({
        items: 1,
        dots: isDots,
        loop: isLooped,
        video: true,
        autoplay: true,
        mouseDrag: isDraggable,
        touchDrag: isDraggable,
        margin: 0,
        autoHeight: false,
        nav: false,
        animateOut: 'fadeOut',
        navText: ['<span class="a-icon-chevron-left"></span>', '<span class="a-icon-chevron-right"></span>'],
        singleItem: true,
        afterAction: function afterAction(current) {
            current.find('video').get(0).play();
        }
    });

    // add fist and last classes to m-content-navigation elements
    $('.m-content-navigation').find('.m-content-navigation__item--main:first').addClass("m-content-navigation__item--first");
    $('.m-content-navigation').find('.m-content-navigation__item--main:last').addClass("m-content-navigation__item--last");
    $('.m-content-navigation').find('.m-content-navigation__item--sub:last').addClass("m-content-navigation__item--last");

    // add fist and last classes to o-group-navigation elements
    $('.m-group-navigation').find('.dropdown-menu:first').addClass("dropdown-menu--first");

    // initialize tooltips globally
    $(function () {

        $('.m-price-calculator__consumption [data-toggle="tooltip"]').tooltip({
            delay: {
                hide: 0
            },
            html: true
        });
        $('[data-toggle="tooltip"]').tooltip({
            delay: {
                hide: 2000
            },
            html: true
        });
    });

    if ($('body').hasClass('has-sticky')) {
        $(document).on('scroll', function (e) {
            var header = $('header.header');
            var headerHeight = header.height();

            var headerGroupScrollHeight = 0;
            var headerGroup = header.find('.m-group-navigation');
            if (headerGroup.length > 0) {
                var headerGroupHeight = headerGroup.height();

                headerGroupScrollHeight = $(document).scrollTop();
                if (headerGroupScrollHeight >= headerGroupHeight) {
                    headerGroupScrollHeight = headerGroupHeight;
                }
            }

            header.addClass('fixed-shadow');
            if (parseInt($(document).scrollTop()) === 0) {
                header.removeClass('fixed-shadow');
            }
            header.css('top', -headerGroupScrollHeight);
            header.addClass('fixed-top');
            $('main.main').css('margin-top', headerHeight);
        });
    }

    $('#carbon-calculator').submit(function () {

        var consumptionHouseTypes = {
            0: 'Passivhaus',
            100: 'MFH Neubau',
            150: 'EFH Neubau',
            200: 'EFH energietisch gut modernisiert',
            300: 'Durchschnittliches Wohngebäude',
            350: 'MFH energetisch nicht wesentlich modernisiert',
            400: 'EFH energetisch nicht wesentlich modernisiert'
        };

        var consumption = parseInt($(this).find('[name="consumption"]').val());
        var sqareMeters = parseInt($(this).find('[name="sqareMeters"]').val());

        var consumptionPerSquareMeter = consumption / sqareMeters;
        var co2 = consumption * 0.0002016;
        var price = consumption * 0.0011;

        var result = $('#carbon-calculator--result');

        result.find('[data-result="consumptionPerSquareMeters"] span').text(parseFloat(consumptionPerSquareMeter.toFixed(2)).toLocaleString());
        result.find('[data-result="co2"] span').text(parseFloat(co2.toFixed(2)).toLocaleString());
        result.find('[data-result="price"] span').text(parseFloat(price.toFixed(2)).toLocaleString());

        var resultHouseType = result.find('[data-result="houseType"] span');

        if (consumptionPerSquareMeter < 0) consumptionPerSquareMeter = 0;

        if (consumptionPerSquareMeter >= 0 && consumptionPerSquareMeter < 100) {
            resultHouseType.text(consumptionHouseTypes[0]);
        } else if (consumptionPerSquareMeter >= 100 && consumptionPerSquareMeter < 150) {
            resultHouseType.text(consumptionHouseTypes[100]);
        } else if (consumptionPerSquareMeter >= 150 && consumptionPerSquareMeter < 200) {
            resultHouseType.text(consumptionHouseTypes[150]);
        } else if (consumptionPerSquareMeter >= 200 && consumptionPerSquareMeter < 300) {
            resultHouseType.text(consumptionHouseTypes[200]);
        } else if (consumptionPerSquareMeter >= 350 && consumptionPerSquareMeter < 400) {
            resultHouseType.text(consumptionHouseTypes[350]);
        } else {
            resultHouseType.text(consumptionHouseTypes[400]);
        }
        result.removeClass('d-none');

        var resultScale = result.find('.a-carbon-scale');
        var resultScaleWidth = resultScale.find('.a-carbon-scale__container').width();
        var resultScaleIndicator = resultScale.find('.a-carbon-scale__indicator');

        if (consumptionPerSquareMeter > 400) consumptionPerSquareMeter = 391;

        var resultScaleIndicatorLeft = resultScaleWidth / (400 / consumptionPerSquareMeter);

        resultScaleIndicator.css({ left: resultScaleIndicatorLeft + 'px' });

        return false;
    });

    $('#m-contract-resignation-form').submit(function () {
        var uri = $(this).attr('action');
        var code = $(this).find('[name="code"]').val();

        window.location.href = uri + code;

        return false;
    });

    $('.news-carousel').owlCarousel({
        loop: true,
        items: 1,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 1,
                nav: false,
                loop: false
            }
        }
    });

    $('.m-price-calculator [name="tx_leu_pi2[postal]"]').focus();

    calculatorPlaceholderLogic($('#carbon-calculator [name="consumption"]'));
    calculatorPlaceholderLogic($('#carbon-calculator [name="sqareMeters"]'));
    calculatorPlaceholderLogic($('#m-price-calculator-form [name="tx_leu_pi2[consumption]"]'));

    var oForm = $('.o-form');
    if (oForm.length > 0) {
        if ($(window).width() >= 768) {
            var pricingTable = $('.m-pricing-table__item');
            var pricingTableHeight = pricingTable.height();
            var pricingTableWidth = pricingTable.width();

            var formPos = oForm.position().top;
            var formHeight = oForm.height();

            var checkoutLeft = $('.checkout-left');
            var checkoutRight = $('.checkout-right');

            if (checkoutLeft.height() > checkoutRight.height()) {
                $(window).scroll(function () {
                    var headerHeight = $('header.header').height();
                    var scrollPos = $(window).scrollTop() + headerHeight;

                    if (scrollPos >= formPos && scrollPos + pricingTableHeight < formPos + formHeight) {
                        pricingTable.css({
                            position: 'fixed',
                            top: $('header.header').height(),
                            width: pricingTableWidth
                        });
                    } else if (scrollPos + pricingTableHeight >= formPos + formHeight) {
                        pricingTable.css({
                            top: formPos + formHeight - (scrollPos + pricingTableHeight) + headerHeight
                        });
                    } else {
                        pricingTable.css({
                            position: 'relative',
                            top: 0
                        });
                    }
                });
            }
        } else {
            $('.checkout-right').css({ marginTop: '100px' });
        }
    }
});

function calculatorPlaceholderLogic(element) {
    element.focus(function () {
        if ($(this).attr('placeholder') === $(this).val()) {
            $(this).val('');
            $(this)[0].placeholder = '';
        }
    });
    element.blur(function () {
        if ($(this).val().length === 0) {
            $(this).val($(this).attr('data-placeholder'));
        }
        $(this).attr('placeholder', $(this).attr('data-placeholder'));
    });
}

var oHeaderLinks = [];

$(function () {
    var shrinkHeader = 162;
    $('.t-e4y__main').scroll(function () {
        var scroll = $(this).scrollTop();
        var body = $('.t-e4y');
        if (scroll >= shrinkHeader) {
            body.addClass('is-sticky');
        } else {
            body.removeClass('is-sticky');
        }
    });

    $('.o-navbar ul li').each(function () {
        var elementAnchor = $(this).children('a').attr('href');
        if (elementAnchor.substr(0, 1) !== '#') return true;
        if (elementAnchor.length <= 1) return true;

        oHeaderLinks[elementAnchor.substr(1)] = $(elementAnchor).position().top - parseFloat($('.t-e4y').css('padding-top'));
    });

    $('.o-navbar ul li a').click(function (e) {
        var elementAnchor = $(this).attr('href');
        if (oHeaderLinks[elementAnchor.substr(1)] !== undefined) {
            e.stopPropagation();
            $('.t-e4y__main').animate({
                scrollTop: oHeaderLinks[elementAnchor.substr(1)]
            }, 800);
            return false;
        }
    });
});

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict';

    window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('validate');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();